/* eslint-env node */
/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */

/* global Turbolinks:true */
import $ from 'jquery';
import Foundation from 'foundation-sites'; // eslint-disable-line no-unused-vars

require('trix');
import Rails from '@rails/ujs';
require('turbolinks').start();
require('@rails/activestorage').start();
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

Rails.start();

if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/sw.js', {scope: './'});
}

$(document).on('open.zf.reveal', () => {
    if ($('.dropdown-pane').length > 0) {
        $('.dropdown-pane').foundation('close');
    }
});

const resizeEmailClient = () => {
    const $emailClient = $('#email-client');

    if ($emailClient.length) {
        const availHeight = window.innerHeight - $emailClient[0].offsetTop - 24;
        $emailClient.height('auto');
        const neededHeight = $emailClient[0].offsetHeight || Infinity;
        $emailClient.height(Math.min(availHeight, neededHeight));
    }
};

const handleCreditCardPayment = () => {
    $('#method_one_credit_card').click(() => {
        const amountRequired = document.getElementById('amount-required').innerText;
        $('#amount-field').slideUp('slow', () => {
            document.getElementById('amount').value = amountRequired.replace(/[^\d.-]/g, '');
        });
        document.getElementById('amount-to-pay').innerText = amountRequired;
    });
    $('#method_several_credit_cards').click(() => {
        document.getElementById('amount').value = '';
        $('#amount-field').hide().removeClass('hide').slideDown('slow');
        document.getElementById('amount-to-pay').innerText = '';
    });

    $('#amount-field #amount').keyup(e => {
        document.getElementById('amount-to-pay').innerText = `$${e.target.value}`;
    });
};

document.addEventListener('turbolinks:load', () => {
    $('.js-only').removeClass('js-only');
    $(document).foundation();
    resizeEmailClient();
    handleCreditCardPayment();
});

window.addEventListener('resize', () => {
    resizeEmailClient();
});

document.addEventListener('ajax:complete', event => {
    let referrer, snapshot;
    const xhr = event.detail[0];

    if ((xhr.getResponseHeader('Content-Type') || '').substring(0, 9) === 'text/html') {
        referrer = window.location.href;
        snapshot = Turbolinks.Snapshot.wrap(xhr.response);
        Turbolinks.controller.cache.put(referrer, snapshot);
        return Turbolinks.visit(referrer, {
            action: 'restore'
        });
    }

    return true;
}, false);

addEventListener('direct-upload:initialize', event => {
    const {target, detail} = event;
    const {id, file} = detail;
    target.insertAdjacentHTML('beforebegin', `
    <div id="direct-upload-${id}" class="direct-upload direct-upload-pending">
      <div id="direct-upload-progress-${id}" class="direct-upload-progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `);
    target.previousElementSibling.querySelector('.direct-upload__filename').textContent = file.name;
});

addEventListener('direct-upload:start', event => {
    const {id} = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload-pending');
});

addEventListener('direct-upload:progress', event => {
    const {id, progress} = event.detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    progressElement.style.width = `${progress}%`;
});

addEventListener('direct-upload:error', event => {
    event.preventDefault();
    const {id, error} = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
});

addEventListener('direct-upload:end', event => {
    const {id} = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
});

$(document).click('.canned-text', e => {
    const $cannedText = $(e.target).closest('.canned-text');
    const emailBody = document.getElementById('email_body');

    if (emailBody && $cannedText.length) {
        const content = $cannedText.find('.trix-content').html();
        const trixEditor = emailBody.editor;
        trixEditor.insertHTML(content);
        trixEditor.undoManager.createEntry();
        $('#canned-text-modal').foundation('close');
    }
});